import router from "./router"
import store from "./store"
import Cookies from "js-cookie"
import NProgress from "nprogress" // progress bar
import Layout from "@/layout"
import { Message, MessageBox } from "element-ui"
import "nprogress/nprogress.css" // progress bar style
import { getToken, setToken } from "./store/module/user"
import settings from "@/store/module/settings"
import { util } from "@/utils/common"

const MyServiceModeKey = "my_vue_admin_service_mode"

const title = settings.state.title

export function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}

const allowedRoutes = [
  "admin/index",
  "admin/clearCache",
  "menu/index",
  "menu/indexNew",
  "menu/AddMenu",
  "menu/AddMenuNew",
  "member/index",
  "member/addMember",
  "Authorize/index",
  "Authorize/addGroup",
  "department/index",
  "customer/index",
  "Task/index",
  "Task/details",
  "customerFollow/index",
  "talentAccount/index",
  "advertiseOrder/index",
  "advertiseOrder/revenue_stat",
  "advertiseOrder/logList",
  "staff/index",
  "staff/updateStaff",
  "staff/staffData",
  "clothing/index",
  "clothing/updateUser",
  "clothing/restrictMakeup",
  "clothing/restrictShack",
  "clothing/addUser",
  "clothing/aggregate",
  "clothing/monthCollect",
  "accountSchedule/index",
  "dynamic/tagsList",
  "dynamic/taskIndex",
  "dynamic/targetReplay",
  "dynamic/setUp",
  "dynamic/statCheck",
  "dynamic/newGuide",
  "purchase/index",
  "purchase/order",
  "assets/index",
  "staff/attendance",
  "Register/index",
  "InventoryDetails/index",
  "assets/select",
  "Todolist/index",
  "dynamic/meeting",
  "penguin/qehUser",
  "penguin/qehEarnings",
  "penguin/qehContent",
  "penguin/qehAssets",
  "penguin/czhUser",
  "penguin/czhEarnings",
  "workTable/index",
  "assets/phone",
  "staff/identity",
  "scheduling/index",
  "scheduling/listView",
  "dynamic/pointIndex",
  "demand/index_new",
  "feedback/index",
  "about/shedList",
  "about/summaryShed",
  "about/clothing",
  "about/summaryClothing",
  "about/aboutMember",
  "about/deptMember",
  "about/summaryCamera",
  "about/summaryRentingHouse",
  "about/blackMember",
  "content/account",
  "content/archive",
  //'content/index',
  "content/index_data",
  "content/day_data",
  "content/count",
  "content/promotion",
  "content/account_stat",
  "business/statistics",
  "business/access_log",
  "movieAccount/index",
  "movieAccount/count",
  "movieAccount/index_data",
  "dynamic/targetIndex",
  "dynamic/planIndex",
  "dynamic/experienceNew",
  "staff/candidate",
  "staff/candidateTalent",
  "staff/candidate_data",
  "staff/statistic",
  "put/account_index",
  "put/calendar_index",
  "put/consume_index",
  "put/statistics",
  "assets/statistics",
  "Live/Acc",
  "Live/AccOrder",
  "Live/Material",
  "Live/AccStat",
  "Live/DirectOrder",
  "Live/AccOrderSubscribe",
  "Live/AccOrderSubWrapper",
  "Live/AccOrderWrapper",
  "Live/components/createLivePlan",
  "Live/AccVideo",
  "Live/AccDirect",
  "Live/StopStrategy",
  "Live/LivePlan",
  "Live/VideoPlan",
  "product/index_list",
  "lovebox/index",
  "cameraman/score",
  "makeup/score",
  "dress/score",
  "platforms/apply",
  "platforms/apply_manage",
  "income/commission",
  "income/senduser",
  "income/ecomm_commission",
  "income/ecommSendUser",
  "income/platform",
  "income/platformSendUser",
  "interpolation/publish",
  "interpolation/record",
  "interpolation/dynamic",
  "interpolation/statistic",
  "interpolation/rankList",
  "bookkeeping/index",
  "productlibrary/index",
  "put/doujia",
  "material_library/index",
  "finance/index",
  "finance/manageIndex",
  "finance/financeIndex",
  "finance/bossIndex",
  "finance/financeManageIndex",
  "finance/cashierIndex",
  "design/index",
  "wechatVideoAccount/acc",
  "wechatVideoAccount/works",
  "contentWorkOrder/content_work_order",
  "contentWorkOrder/clip_confirm_order",
  "contentWorkOrder/clip_order_list",
  "contentWorkOrder/clip_user_group",
  "contentWorkOrder/work_schedule",
  "contentWorkOrder/clip_black",
  "contentWorkOrder/direct_group",
  "contentWorkOrder/difficulty_rank",
  "contentWorkOrder/score_table",
  "contentWorkOrder/account_schedule",
  "contentWorkOrder/clip_order_rule",
  "contentWorkOrder/index_rank",
  "contentWorkOrder/clip_stat",
  "Performance/Account",
  "Performance/CommerceDay",
  "Performance/CommerceMonth",
  "Performance/Product",
  "Performance/export",
  "finance/data_stat",
  "RedBook/index",
  "RedBook/fans",
  "Live/ActivityStatistics",
  "kuaishou/account",
  "kuaishou/video",
  "newassets/assets",
  "newassets/register",
  "newassets/data_stat",
  "newassets/damageReport",
  "newassets/dataReport",
  "newassets/purchase",
  "full_attendance/index",
  "tv/banner",
  "short_assets/assets",
  "short_assets/register",
  "short_assets/data_stat",
  "short_assets/dataReport",
  "short_assets/purchase",
  "other/index",
  "miniprogram_msg/index",
  "virtualAssets/firstDomain",
  "virtualAssets/secondDomain",
  "xt/account",
  "staff/new_attendance",
  "system/productTest/index",
  "newStaff/new_attendance",
  "newStaff/new_candidate_data",
  "talent/income",
  "approve/index",
  "approve/formsPanel",
  "approve/record",
  "approve/log",
  "requirement/index",
  "Live/Schedule",
  "Live/ScheduleStatistics",
  "finance/log",
  "ada_assets/setting",
  "ada_assets/assets",
  "ada_assets/useAssets",
  "ada_assets/purchaseManage",
  "ada_assets/log",
  "ada_assets/statistics",
]

function addNewRoutes(newroutes, routes) {
  let newroute = {
    id: 0,
    path: "/",
    component: Layout,
    meta: { title: "后台首页" },
  }
  if (routes.length > 0) {
    newroute["children"] = []

    for (let k in routes) {
      let rout_p =
        allowedRoutes.indexOf(routes[k]["name"]) !== -1
          ? routes[k]["name"]
          : "admin/empty"

      if (routes[k]["name"] == "demand/index_new") {
        console.log("addNewRoutes", rout_p)
      }
      let tmp = import("@/views/" + rout_p)
      newroute["children"].push({
        id: routes[k]["id"],
        path: "/" + routes[k]["name"],
        name: "id" + routes[k]["id"],
        component: () => tmp,
        meta: {
          title: routes[k]["title"],
          icon: routes[k]["icon"],
          affix: false,
        },
      })
    }
  }
  newroutes.push(newroute)
}

function checkRouteExists(newroutes, path) {
  for (let k in newroutes) {
    let newroute = newroutes[k]
    if (path === newroute["path"]) {
      return true
    } else if (newroute["children"]) {
      for (let kk in newroute["children"]) {
        let children = newroute["children"][kk]
        if (children["path"] === path) {
          return true
        }
      }
    }
  }
  return false
}

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = [
  "/admin/login",
  "/admin/qywxlogin",
  "/admin/wwlogin",
  "/admin/fslogin",
  "/admin/fsmdlogin",
  "/admin/qywxauth",
] // no redirect whitelist

let getinfo_data = false

let global_first_route = "/admin/index"

let route_dist_timestamp = null

router.beforeEach(async (to, from, next) => {
  let params = util.getUrlParams(window.location.href)

  if (Object.prototype.hasOwnProperty.call(params, "token")) {
    setToken(params.token)
  }
  NProgress.start()

  document.title = getPageTitle(to.meta.title)

  const hasToken = getToken()

  if (hasToken) {
    if (
      to.path === "/admin/login" ||
      to.path === "/admin/wwlogin" ||
      to.path === "/admin/fslogin" ||
      to.path === "/admin/fsmdlogin" ||
      to.path === "/admin/qywxlogin" ||
      to.path === "/admin/qywxauth"
    ) {
      next({ path: global_first_route })
      NProgress.done()
    } else {
      const hasRoutes = store.getters.routes && store.getters.routes.length > 0
      // console.log('store.getters.routes:', store.getters.routes)
      if (hasRoutes) {
        // console.log('hasRoutes', hasRoutes)
        if (route_dist_timestamp === null) {
          route_dist_timestamp = store.getters.dist_timestamp
        } else if (route_dist_timestamp !== store.getters.dist_timestamp) {
          MessageBox.alert("检测到新版本，点确定刷新当前浏览器", "新版本提示", {
            confirmButtonText: "确定",
            cancelButtonText: "",
            customClass: "theme-del-dialog",
            type: "warning",
            callback: (action) => {
              window.location.reload()
            },
          })
        }

        /* if(to.path === '/') {
          if(store.getters.first_route_path) {
            console.log(store.getters.first_route_path)
            window.location.href = '/#' + store.getters.first_route_path
            window.location.reload()
            // next({path: store.getters.first_route_path, replace: true})
            // NProgress.done()
          }
        }
        else  */
        if (to.path === "/") {
          window.location.reload()
        } else if (
          to.path == "/admin/index" &&
          global_first_route != "/admin/index"
        ) {
          next({ path: global_first_route, replace: true })
        } else {
          next()
        }
      } else if (!getinfo_data) {
        try {
          const { routes, first_route_path, pcRouterConfig } =
            await store.dispatch("user/getInfo")

          let pathname = window.location.pathname
          if (
            process.env.NODE_ENV === "production" &&
            pathname != `/${pcRouterConfig.path}${pcRouterConfig.version}/`
          ) {
            MessageBox.alert(
              "检测到新版本，点确定刷新当前浏览器",
              "新版本提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "",
                customClass: "theme-del-dialog",
                type: "warning",
                callback: (action) => {
                  let domain = pcRouterConfig.domain
                  let currentUrl =
                    `${domain}${pcRouterConfig.path}${pcRouterConfig.version}/#` +
                    to.path
                  window.location.href = currentUrl
                },
              }
            )
          }

          global_first_route = first_route_path

          let newroutes = []
          addNewRoutes(newroutes, routes)

          //console.log('newrouters:', newroutes)
          router.addRoutes(newroutes)
          router.options.routes.push(...newroutes)
          //console.log('router.options.routes:', router.options.routes)
          await store.dispatch("user/setRoutes", newroutes)

          getinfo_data = true
          /* if(newroutes.length > 0 && !checkRouteExists(newroutes, to.path)) {
            next({path: newroutes[0]['path'], replace: true})
          } */

          let cookie_service_mode = Cookies.get(MyServiceModeKey)
          await store.dispatch("user/setServiceMode", cookie_service_mode)

          if (
            (from.path === "/admin/login" ||
              from.path === "/admin/fslogin" ||
              from.path === "/admin/fsmdlogin" ||
              from.path === "/admin/qywxlogin" ||
              from.path === "/admin/qywxauth") &&
            to.path === "/admin/index"
          ) {
            console.log("first_route_path: ", first_route_path)
            next({ path: first_route_path, replace: true })
          } else if (to.path === "/") {
            next({ path: first_route_path, replace: true })
          } else {
            next({ ...to, replace: true })
            // console.log('else: next')
            // next('/')
          }
        } catch (error) {
          // await store.dispatch('user/resetToken')
          Message.error(error || "Has Error")
          // next(`/admin/login?redirect=${to.path}`)
          NProgress.done()
        }
      } else {
        Message.error("未获取到权限信息")
        NProgress.done()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      // 浏览器环境扫码登录
      if (util.isWorkWechat()) {
        next(`/admin/qywxauth?redirect=${to.path}`)
      } else {
        next(`/admin/qywxlogin?redirect=${to.path}`)
      }
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  //console.log('router.afterEach')
  // finish progress bar
  NProgress.done()
})

export function setGetInfoDataValue(val = false) {
  getinfo_data = val
}
